import React, { useContext, useState, useMemo } from 'react'
import { User } from '../data/models'
import { storage } from '../data/storage'

export type IAppContext = { user: User | null; setUser: (user: User) => unknown }

const AppContext = React.createContext<IAppContext>({ user: null, setUser: () => {} })

interface Props {
  children?: React.ReactNode;
}


export const AppContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(storage.getUser())

  const value = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)

import { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

import { SessionContext } from '../components/MainLayout'
import { RequestStatus } from './useRequest'

export const useSuccessToast = (requestStatus: RequestStatus, msg: string, navigateTo?: string) => {
  const navigate = useNavigate()
  const { resetDirty } = useContext(SessionContext)

  useEffect(() => {
    if (requestStatus === 'success') {
      toast.success(msg)
      resetDirty()
      if (navigateTo) {
        navigate(navigateTo)
      }
    }
  }, [requestStatus, msg, navigateTo, resetDirty, navigate])
}

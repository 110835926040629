export type Role = 'user' | 'admin' | 'super_admin' | 'operator' | 'operator_manager'
export interface User {
  id: string
  email: string
  role: Role
  first_name: string
  last_name: string
  subscription: {
    id: string
    is_active: boolean
    plan: 'free' | 'base' | 'premium'
  }
  phone_numbers?: { number: string; type: string }[]
  created_at: string
}

export enum Day {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export interface AvailabilitySlot {
  id: string
  day: Day
  start_date: Date
  end_date: Date
}

export interface UserServices {
  total_used_seconds: number
  activations: number
  handled_calls_last_instance?: number
  handled_calls_today?: number
  handled_calls?: number
  lost_calls?: number
  call_seconds_used?: number
  call_seconds_max?: number
  last_activity?: string
}

export interface UserMessage {
  body: string
  duration: string
  created_at: string
  id: string
  sender_name?: string
  sender_phone_number: string
  appointment?: { from: string; to: string }
}

export interface UserContact {
  phone_number: string
  name: string
}

export interface EventType {
  id?: string
  name: string
  color: string
  created_at?: string
  updated_at?: string
}

export interface Period {
  from: string
  to: string
}
export type EventStatus = 'requested' | 'accepted' | 'declined' | 'delete_requested'
export interface Event {
  id?: string
  title: string
  period: Period
  type: EventType
  permissions?: 'public' | 'private'
  status?: EventStatus
  owner?: User | null
  created_at?: string
}

export interface CallDetail {
  id: string
  caller_phone_number: string
  caller_name: string
  called_phone_number: string
  called_id: string
  called_name: string
  is_premium: boolean
  message: Message
  instructions: string
}

export interface Message {
  id: string
  body: string
  created_at: string
  sender_phone_number: string
  sender_name: string
  appointment?: {
    id?: string
    from: Date
    to: Date
  }
}

export type WidgetType = 'digits' | 'table'
export type WidgetPeriod = 'day' | 'week' | 'overall'
export type DigitsWidget = {
  id: string
  type: 'digits'
  props: {
    title: string
    period: WidgetPeriod
  }
  items: {
    description: string
    fraction?: string | null
    value?: string | null
  }[]
}
export type TableWidget = {
  id: string
  type: 'table'
  props: { title: string; period: WidgetPeriod; columns: string[] }
  items: {
    columns: { [column: string]: string }
  }[]
}

export type Dashboard = (DigitsWidget | TableWidget)[]

export enum VoucherType {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount',
  WELFARE = 'welfare',
}

export enum WelfareValidity {
  NINE_MONTHS = '9_months',
  SIX_MONTHS = '6_months',
  THREE_MONTHS = '3_months',
}

export enum SubscriptionPlan {
  FREE = 'free',
  BASE = 'base',
  PREMIUM = 'premium',
}

export type VoucherGroup = {
  id: string
  name: string
  type: VoucherType
  valid_from: string
  valid_to: string
  max_redemptions?: number
  amount?: number
  currency?: string
  validity?: WelfareValidity
  plan?: SubscriptionPlan
  quantity: number
}

export type Voucher = {
  id: string
  type: VoucherType
  code: string
  valid_from: string
  valid_to: string
  max_redemptions?: number
  used?: boolean
}

// create new interface for customer call details API
export interface CustomerCallDetail {
  user_id: string
  time_in_call?: number
  time_off_call?: number
  description?: string
}

export interface CustomerDetail {
  id: string
  first_name: string
  last_name: string
  email: string,
  full_name: string
  phone_numbers: Array<any>
  service_seconds:number
}
import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import { LoginPage } from './modules/auth/Login'
import './styles/global.css'
import { UsersPage } from './modules/users/Users'
import { PasswordReset } from './modules/auth/PasswordReset'
import { PasswordOTP } from './modules/auth/PasswordOTP'
import { UpdatePassword } from './modules/auth/UpdatePassword'
import { MainLayout } from './components/MainLayout'
import { ProfilePage } from './modules/profile/Profile'
import { CreateUser } from './modules/users/CreateUser'
import { UserDetail } from './modules/users/UserDetail'
import { DashboardPage } from './modules/dashboard/Dashboard'
import { OperatorDashboardPage } from './modules/operatorDashboard/OperatorDashboard'
import { CallWithClient } from './modules/operatorDashboard/CallWitchClient'
import { CallWithCustomer } from './modules/operatorDashboard/CallWithCustomer'
import { Export } from './modules/export/Export'
import { VoucherGroupsPage } from './modules/vouchers/VoucherGroups'
import { CreateVoucherGroup } from './modules/vouchers/CreateVoucherGroup'
import { VoucherGroupDetail } from './modules/vouchers/VoucherGroupDetail'
import { useAppContext } from './components/AppContext'
import { Role } from './data/models'

const PrivateRoute = ({
  component: Component,
  header,
  roles = [],
  ...rest
}: {
  component: React.ComponentType
  header: React.ComponentProps<typeof MainLayout>
  roles?: Role[]
  [key: string]: any
}) => {
  const { user } = useAppContext()

  const canView = roles.length === 0 ? user !== null : user !== null && roles.includes(user.role)
  return (
    // comment this code becuase it wasn't working on new update.
    // <Route
    //   {...rest}
    //   Component={(props) =>
        canView ? (
          <MainLayout {...header}>
            <Component/>
          </MainLayout>
        ) : (
          <Navigate to="/login" />
        )
    //   }
    // />
  )
}

function App() {
  const { user } = useAppContext()
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/password-otp" element={<PasswordOTP />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route
            path="/"
            element={
              <Navigate
                to={
                  !user?.role
                    ? '/login'
                    : user.role !== 'operator_manager'
                    ? '/dashboard'
                    : '/users'
                }
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                component={(user?.role !== 'operator' && user?.role !== 'operator_manager') ? DashboardPage : OperatorDashboardPage}
                roles={['super_admin', 'admin', 'operator', 'operator_manager']}
                header={{
                  title: (user?.role !== 'operator' && user?.role !== 'operator_manager') ? 'Dashboard' : 'Benvenuto in MySecretary',
                }}
              />
            }
          />
          <Route
            path="/users/create"
            element={
              <PrivateRoute
                component={CreateUser}
                roles={['super_admin', 'admin', 'operator_manager']}
                header={{
                  title: 'Aggiungi nuovo utente',
                  backLink: {
                    text: 'Torna alla lista utenti',
                    to: '/users',
                  },
                }}
              />
            }
          />
          <Route
            path="/users/:id"
            element={
              <PrivateRoute
                component={UserDetail}
                roles={['super_admin', 'admin', 'operator_manager']}
                header={{
                  title: 'Dettaglio Utente',
                  backLink: {
                    text: 'Torna alla lista utenti',
                    to: '/users',
                  },
                }}
              />
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute
                component={UsersPage}
                roles={['super_admin', 'admin', 'operator_manager']}
                header={{
                  title: 'Utenti',
                  headerButton: {
                    text: 'Aggiungi nuovo utente',
                    to: '/users/create',
                  },
                }}
              />
            }
          />
          <Route
            path="/calls/:id"
            element={
              <PrivateRoute
                component={CallWithClient}
                roles={['operator']}
                header={{
                  title: 'Gestione chiamata in corso',
                }}
              />
            }
          />
          <Route
            path="/export"
            element={
              <PrivateRoute
                component={Export}
                roles={['super_admin', 'admin']}
                header={{
                  title: 'Esportazione dati su excel',
                }}
              />
            }
          />
          <Route
            path="/vouchers/create"
            element={
              <PrivateRoute
                component={CreateVoucherGroup}
                roles={['super_admin', 'admin']}
                header={{
                  title: 'Genera codici',
                  backLink: {
                    text: 'Torna alla lista dei codici',
                    to: '/vouchers',
                  },
                }}
              />
            }
          />
          <Route
            path="/vouchers/:id"
            element={
              <PrivateRoute
                component={VoucherGroupDetail}
                roles={['super_admin', 'admin']}
                header={{
                  title: 'Dettaglio Codici',
                  backLink: {
                    text: 'Torna alla lista dei codici',
                    to: '/vouchers',
                  },
                }}
              />
            }
          />
          <Route
            path="/vouchers"
            element={
              <PrivateRoute
                component={VoucherGroupsPage}
                roles={['super_admin', 'admin']}
                header={{
                  title: 'Codici',
                  headerButton: {
                    text: 'Genera nuovi codici',
                    to: '/vouchers/create',
                  },
                }}
              />
            }
          />
          <Route
            path="/me"
            element={
              <PrivateRoute
                component={ProfilePage}
                header={{
                  title: 'Profilo',
                }}
              />
            }
          />
          <Route
            path="/call-with-customer/:id_or_phone_number"
            element={
              <PrivateRoute
                component={CallWithCustomer}
                roles={['operator', 'operator_manager']}
                header={{
                  title: 'Gestione assistenza in corso',
                }}
              />
            }
          />
          {/* <PrivateRoute
            path="/dashboard"
            component={user?.role !== 'operator' ? DashboardPage : OperatorDashboardPage}
            roles={['super_admin', 'admin', 'operator']}
            header={{
              title: user?.role !== 'operator' ? 'Dashboard' : 'Benvenuto in MySecretary',
            }}
          /> */}
          {/* <PrivateRoute
            path="/users/create"
            component={CreateUser}
            roles={['super_admin', 'admin', 'operator_manager']}
            header={{
              title: 'Aggiungi nuovo utente',
              backLink: {
                text: 'Torna alla lista utenti',
                to: '/users',
              },
            }}
          />
          <PrivateRoute
            path="/users/:id"
            component={UserDetail}
            roles={['super_admin', 'admin', 'operator_manager']}
            header={{
              title: 'Dettaglio Utente',
              backLink: {
                text: 'Torna alla lista utenti',
                to: '/users',
              },
            }}
          />
          <PrivateRoute
            path="/users"
            component={UsersPage}
            roles={['super_admin', 'admin', 'operator_manager']}
            header={{
              title: 'Utenti',
              headerButton: {
                text: 'Aggiungi nuovo utente',
                to: '/users/create',
              },
            }}
          />
          <PrivateRoute
            path="/calls/:id"
            component={CallWithClient}
            roles={['operator']}
            header={{
              title: 'Gestione chiamata in corso',
            }}
          />
          <PrivateRoute
            path="/export"
            component={Export}
            roles={['super_admin', 'admin']}
            header={{
              title: 'Esportazione dati su excel',
            }}
          />
          <PrivateRoute
            path="/vouchers/create"
            component={CreateVoucherGroup}
            roles={['super_admin', 'admin']}
            header={{
              title: 'Genera codici',
              backLink: {
                text: 'Torna alla lista dei codici',
                to: '/vouchers',
              },
            }}
          />
          <PrivateRoute
            path="/vouchers/:id"
            component={VoucherGroupDetail}
            roles={['super_admin', 'admin']}
            header={{
              title: 'Dettaglio Codici',
              backLink: {
                text: 'Torna alla lista dei codici',
                to: '/vouchers',
              },
            }}
          />
          <PrivateRoute
            path="/vouchers"
            component={VoucherGroupsPage}
            roles={['super_admin', 'admin']}
            header={{
              title: 'Codici',
              headerButton: {
                text: 'Genera nuovi codici',
                to: '/vouchers/create',
              },
            }}
          />
          <PrivateRoute
            path="/me"
            component={ProfilePage}
            header={{
              title: 'Profilo',
            }}
          /> */}
        </Routes>
      </Router>
    </>
  )
}

export default App

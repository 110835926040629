import { Subtitle } from '@duckma/react-ds'
import React from 'react'
import styled from 'styled-components'
import { storage } from '../../data/storage'

export const OperatorDashboardPage = () => {
  if (storage.getUser()?.role !== 'operator' && storage.getUser()?.role !== 'operator_manager') {
    window.location.reload()
  }

  return (
    <Container>
      <OperatorSubtitle>
        Lascia aperta questa finestra in attesa della chiamata da parte di un utente di MySecretary
      </OperatorSubtitle>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 50px;
`
const OperatorSubtitle = styled(Subtitle)`
  color: #80c5dd;
  padding: 0 40px;
  font-size: 16px;
  margin-top: 0;
`

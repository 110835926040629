import React, { useContext, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Sidebar, ThemeContext, Button, Theme } from '@duckma/react-ds'

import Logo from '../images/logo-light.png'
import { storage } from '../data/storage'
import { useLocation, useNavigate } from 'react-router-dom'
import { api } from '../data/api'
import { Header } from './Header'
import { Role, User } from '../data/models'
import packageInfo from '../../package.json'
// import { version } from '../../package.json'
import { IconName } from '@duckma/react-ds/dist/assets/icons/GeneratedIcons'

const sections: { name: string; label: string; icon: IconName; canSeeIf: Role[] }[] = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    icon: 'dashboard',
    canSeeIf: ['super_admin', 'admin', 'operator', 'operator_manager'],
  },
  {
    name: 'users',
    label: 'Utenti',
    icon: 'user-alt',
    canSeeIf: ['super_admin', 'admin', 'operator_manager'],
  },
  {
    name: 'vouchers',
    label: 'Codici',
    icon: 'folder',
    canSeeIf: ['super_admin', 'admin'],
  },
  {
    name: 'export',
    label: 'Esportazione excel',
    icon: 'arrow-bottom',
    canSeeIf: ['super_admin', 'admin'],
  },
]

export const SessionContext = React.createContext<{
  user?: User
  mustReload: () => void
  flagDirty: () => void
  resetDirty: () => void
}>({ mustReload: () => {}, flagDirty: () => {}, resetDirty: () => {} })

export const MainLayout: React.FC<{
  title: string
  backLink?: { to: string; text: string }
  headerButton?: { text: string; to: string }
  headerLeft?: React.ReactNode
  children?: React.ReactNode
}> = ({ children, title, backLink, headerButton, headerLeft }) => {
  const theme = useContext(ThemeContext) as Theme
  const location = useLocation()
  const navigate = useNavigate()
  // If a form is dirty (with unsaved user work on it) the page sets dirty to true.
  // If the user then tries to go back, a popup is shown
  const [dirty, setDirty] = useState(false)

  const [user, setUser] = useState(storage.getUser())

  const mustReload = useCallback(() => setUser(storage.getUser()), [])
  const flagDirty = useCallback(() => {
    window.onbeforeunload = () => ''
    setDirty(true)
  }, [])
  const resetDirty = useCallback(() => {
    window.onbeforeunload = () => null
    setDirty(false)
  }, [])

  return (
    <Grid>
      <SidebarContainer>
        <Sidebar
          structure={sections.filter((s) => s.canSeeIf.includes(user?.role ?? 'user'))}
          pinned={[
            {
              name: 'me',
              label: user.first_name + ' ' + user.last_name,
              icon: 'admin',
              showArrow: false,
            },
            {
              name: 'logout',
              label: 'Logout',
              showArrow: false,
            },
          ]}
          logo={Logo}
          logoStyles={{ width: '50%', paddingLeft: '20%' }}
          selected={location.pathname.split('/')[1]}
          color="primary100"
          selectedColor="primary50"
          version={packageInfo.version}
          onSelect={(name) => {
            switch (name) {
              case 'logout':
                api.clearSession()
                navigate('/login')
                break
              default:
                if (dirty) {
                  window.location.href = `/${name}`
                } else {
                  navigate(`/${name}`)
                }
                break
            }
          }}
        />
      </SidebarContainer>
      <HeaderContainer color={theme['gray20']}>
        <Header
          title={title}
          backLink={backLink}
          dirty={dirty}
          leftSide={
            headerButton ? (
              <Button
                text={headerButton.text}
                onClick={() => {
                  if (dirty) {
                    return (window.location.href = headerButton.to)
                  }
                  navigate(headerButton.to)
                }}
                size="large"
                radius={4}
              />
            ) : (
              headerLeft
            )
          }
        ></Header>
      </HeaderContainer>
      <SessionContext.Provider value={{ mustReload, flagDirty, resetDirty, user }}>
        <ContentContainer color={theme['gray20']}>{children}</ContentContainer>
      </SessionContext.Provider>
    </Grid>
  )
}

const Grid = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: 160px 1fr;
`

const SidebarContainer = styled.div`
  width: 260px;
  max-height: 100vh;
  height: 100%;
  grid-area: 1 / 1 / 3 / 2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

const HeaderContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 100%;
  grid-area: 1 / 2 / 2 / 3;
`

const ContentContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 100%;
  grid-area: 2 / 2 / 3 / 3;
`

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, DateTimePicker, Title, Subtitle } from '@duckma/react-ds'
import it from 'date-fns/locale/it'
import { saveAs } from 'file-saver'
import moment from 'moment'
import toast from 'react-hot-toast'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'

const formatDate = (date: Date) => moment(date).format('DD/MM/YYYY')
export const Export = () => {
  const [from, setFrom] = useState<Date>(new Date())
  const [to, setTo] = useState<Date>()
  const [tried, setTried] = useState(false)
  const [getAllUsers, { status: statusAll, data: dataAll }, allReset] = useRequest(
    api.getExportAllUsers
  )
  const [getActiveUsers, { status: statusActive, data: dataActive }, activeReset] = useRequest(
    api.getExportActiveUsers
  )

  useEffect(() => {
    if (dataAll) {
      const blob = new Blob([dataAll], { type: 'text/plain;charset=utf-8' })
      const filename = `export-all.csv`
      saveAs(blob, filename)
      allReset()
    }
  }, [allReset, dataAll])

  useEffect(() => {
    if (from !== undefined) {
      setTried(false)
    }
  }, [from])

  useEffect(() => {
    if (dataActive && from !== undefined) {
      const blob = new Blob([dataActive], { type: 'text/plain;charset=utf-8' })
      const filename = `export-from-${formatDate(from)}-to-${formatDate(to ? to : from)}.csv`
      saveAs(blob, filename)
      activeReset()
    }
  }, [activeReset, dataActive, from, to])

  return (
    <Container>
      <AppUsers>
        <ExportForm
          onSubmit={(e) => {
            e.preventDefault()
            if (from === undefined) {
              setTried(true)
              toast.error('Data di inizio necessaria')
              return
            }
            getActiveUsers({ from: from.toISOString(), to: (to ? to : from).toISOString() })
          }}
        >
          <ExportTitle> Esportazione statistiche comportamento utenti di MySecretary</ExportTitle>
          <ExportSubtitle>
            Esportazione statistiche di utilizzo (estrae solo chi ha usato l'app nel periodo)
          </ExportSubtitle>
          <ExportDateTimePicker
            name="data-inizio-*"
            locale={it}
            tried={tried}
            placeholderText="Seleziona data di inizio"
            style={{ marginRight: '2%' }}
            selected={from}
            onChange={setFrom}
          />

          <ExportDateTimePicker
            name="data-fine"
            locale={it}
            placeholderText="Seleziona data di fine"
            selected={to}
            onChange={setTo}
          />

          <ExportPs>
            Lasciando vuota la data finale, verranno estratte solo le statistiche della data
            iniziale(quindi quella del gioirno selezionato)
          </ExportPs>
          <Button
            loading={statusActive === 'loading'}
            type="submit"
            text="Estrai"
            style={{ width: '100px', padding: '15px', fontWeight: '400' }}
            radius={5}
          />
        </ExportForm>
      </AppUsers>

      <AllUsers>
        <ExportTitle> Esportazione utenti</ExportTitle>
        <ExportSubtitle>Esportazione di tutti gli utenti</ExportSubtitle>

        <Button
          onClick={() => getAllUsers({})}
          loading={statusAll === 'loading'}
          text="Esporta utenti"
          style={{ width: '150px', padding: '15px', fontWeight: '400' }}
          radius={5}
        />
      </AllUsers>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const AppUsers = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 40px;
`

const AllUsers = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 10px;
`

const ExportDateTimePicker = styled(DateTimePicker)<{ tried?: boolean }>`
  width: 48%;
  input {
    border-color: ${(props) => (props.tried === true ? '#ff5959' : '')};
  }
`

const ExportTitle = styled(Title)`
  font-size: 18px;
  color: #2b2b2b;
`

const ExportSubtitle = styled(Subtitle)`
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  margin-bottom: 40px;
  font-weight: 400;
`

const ExportForm = styled.form`
  width: 100%;
  display: flex;
  flex-flow: wrap;
`

const ExportPs = styled.p`
  margin-top: 25px;
  font-size: 12px;
  margin-bottom: 40px;
  color: #828894;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
`

export type Languages = 'it'

export const translate = (key: string) => dashboardLabels[key]?.it ?? key

export const dashboardLabels: { [key: string]: { [key in Languages]: string } } = {
  users: {
    it: 'Utenti',
  },
  overall: {
    it: 'totale',
  },
  week: {
    it: 'settimana',
  },
  day: {
    it: 'oggi',
  },
}

  import React, { useEffect } from 'react'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { useParams } from 'react-router-dom'
import { ClientDetail } from './components/ClientDetail'
import { AdminDetail } from './components/AdminDetail'

export const UserDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [getUser, { status, data }] = useRequest(api.getUser)

  useEffect(() => {
    if (status === 'idle') {
      getUser({ id })
    }
  }, [status, getUser, id])

  if (status !== 'success' || !data) return <div />

  return data?.role === 'user' ? <ClientDetail user={data} /> : <AdminDetail user={data} />
}

import React, { useState } from 'react'
import styled from 'styled-components'
import { Tabs } from '@duckma/react-ds'

import { User } from '../../../data/models'
import { ClientDataTab } from './ClientDataTab'
import { CallsTab } from './CallsTab'

export const ClientDetail: React.FC<{ user: User }> = ({ user }) => {
  const [currentTab, setCurrentTab] = useState('data')
  return (
    <Container>
      <Tabs
        currentTab={currentTab}
        onTabChange={setCurrentTab}
        tabs={[
          {
            key: 'data',
            label: 'Dati',
            child: <ClientDataTab user={user} />,
          },
          {
            key: 'calls',
            label: 'Lista chiamate',
            child: <CallsTab user={user} />,
          },
        ]}
      ></Tabs>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  padding-bottom: 50px;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

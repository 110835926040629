import React from 'react'
import ReactDOM from "react-dom/client"
import { ThemeContext } from '@duckma/react-ds'
import { Toaster } from 'react-hot-toast'

import App from './App'
import { theme } from './styles/theme'
import { AppContextProvider } from './components/AppContext'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeContext.Provider value={theme}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ThemeContext.Provider>
    <Toaster />
  </React.StrictMode>
)

import React, { useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Button, DateTimePicker, NumericInput, Radios } from '@duckma/react-ds'

import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api, CreateVoucherGroup as TCreateVoucherGroup } from '../../data/api'
import { requiredValidator } from '../../utils/validators'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { Select } from '../../components/Select'
import { SubscriptionPlan, VoucherType, WelfareValidity } from '../../data/models'
import _ from 'lodash'

const typeChoices = [
  { key: 'welfare', label: 'Codice Azienda' },
  { key: 'percentage', label: 'Codice sconto' },
]

const welfareTypeOptions = [
  { value: WelfareValidity.THREE_MONTHS, label: '3 mesi' },
  { value: WelfareValidity.SIX_MONTHS, label: '6 mesi' },
  { value: WelfareValidity.NINE_MONTHS, label: '9 mesi' },
]

const subscriptionPlanOptions = [
  { value: SubscriptionPlan.BASE, label: 'Base' },
  { value: SubscriptionPlan.PREMIUM, label: 'Premium' },
]

const percentageOptions = [
  { value: '10', label: '10%' },
  { value: '20', label: '20%' },
  { value: '25', label: '25%' },
  { value: '50', label: '50%' },
]

export const CreateVoucherGroup = () => {
  const [request, { status }] = useRequest(api.createVoucherGroup)

  const [type, setType] = useState('welfare')
  const [values, setValues] = useState<Partial<TCreateVoucherGroup>>({
    amount: 0,
    quantity: 1,
    valid_from: new Date().toISOString(),
  })
  const [valid, setValid] = useState(true)

  const initialValues = useMemo(() => ({}), [])
  const onChange = useCallback((values: any, valid: any) => {
    setValues((v) => ({ ...v, ...values }))
    setValid(valid)
  }, [])

  useSuccessToast(status, 'Codici generati con successo', '/vouchers')

  const amountValid = useMemo(
    () => values.amount !== undefined && values.amount >= 0 && values.amount < 100,
    [values.amount]
  )
  const quantityValid = useMemo(() => values.quantity !== undefined && values.quantity > 0, [
    values.quantity,
  ])

  return (
    <Container>
      <Radios left choices={typeChoices} value={type} onChange={setType} />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          if (type === VoucherType.PERCENTAGE) {
            request({
              ..._.pick(values, ['name', 'amount', 'valid_from', 'valid_to', 'quantity']),
              type,
              currency: 'EUR',
            } as NonNullable<TCreateVoucherGroup>)
          } else {
            request({
              ..._.pick(values, ['name', 'valid_from', 'valid_to', 'quantity', 'validity', 'plan']),
              type,
              currency: 'EUR',
            } as NonNullable<TCreateVoucherGroup>)
          }
        }}
        autoComplete="off"
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <ControlledField name="nome" fieldName="name" validator={requiredValidator()} />
            <NumericInput
              name="quantità"
              valid={quantityValid}
              value={values.quantity}
              onChange={(quantity) => setValues((v) => ({ ...v, quantity }))}
              errorText="Inserici un valore numerico intero superiore a 0"
            />
            <DateTimePicker
              name="attivo-da"
              selected={values.valid_from ? new Date(values.valid_from) : undefined}
              showTimeSelect
              onChange={(valid_from) =>
                setValues((v) => ({ ...v, valid_from: valid_from.toISOString() }))
              }
            />
            <DateTimePicker
              name="scadenza-a"
              selected={values.valid_to ? new Date(values.valid_to) : undefined}
              showTimeSelect
              onChange={(valid_to) =>
                setValues((v) => ({ ...v, valid_to: valid_to.toISOString() }))
              }
            />
            {type === VoucherType.PERCENTAGE && (
              <Select
                name="percentuale"
                label="Percentuale"
                placeholder="Inserisci Percentuale"
                getItemsPromise={() => Promise.resolve(percentageOptions)}
                value={percentageOptions.find(
                  ({ value }) => Number(value) === values.amount ?? '10'
                )}
                onChange={(value) => setValues((v) => ({ ...v, amount: Number(value?.value) }))}
              />
            )}
            {type === VoucherType.WELFARE && (
              <>
                <Select
                  name="tipologia"
                  label="Tipologia"
                  placeholder="Tipologia"
                  getItemsPromise={() => Promise.resolve(welfareTypeOptions)}
                  value={welfareTypeOptions.find(
                    ({ value }) => value === values.validity ?? WelfareValidity.THREE_MONTHS
                  )}
                  onChange={( value ) =>
                    setValues((v) => ({ ...v, validity: value?.value as unknown as WelfareValidity }))
                  }
                />
                <Select
                  name="piano"
                  label="Piano"
                  placeholder="Piano"
                  getItemsPromise={() => Promise.resolve(subscriptionPlanOptions)}
                  value={subscriptionPlanOptions.find(
                    ({ value }) => value === values.plan ?? SubscriptionPlan.BASE
                  )}
                  onChange={(value ) =>
                    setValues((v) => ({ ...v, plan: value?.value as unknown as SubscriptionPlan }))
                  }
                />
              </>
            )}
          </ControlledForm>
        </FieldsContainer>
        <div style={{ flexGrow: 1 }} />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={
              !valid ||
              (type === VoucherType.PERCENTAGE ? !amountValid : !values.validity) ||
              !quantityValid ||
              !values.valid_from ||
              !values.valid_to
            }
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: calc(100% - 20px);
  margin-left: 54px;
  margin-right: 54px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 55px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`

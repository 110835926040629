import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Input } from '@duckma/react-ds'
import { Link } from './components/Link'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { Navigate } from 'react-router-dom'
import { Title, Subtitle } from '@duckma/react-ds'

export const PasswordReset = () => {
  const [email, setEmail] = useState('')
  const [request, { status }] = useRequest(api.recoverPassword)

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ email })
      }}
    >
      {status === 'success' && <Navigate to={{ pathname: '/password-otp' }} state= { email } />}
      <Title text="Recupera Password" style={{ marginBottom: '33px' }} />
      <Subtitle
        text="Inserisci la mail per poter recuperare la password"
        style={{ marginBottom: '84px' }}
      />
      <Input
        name="email"
        type="email"
        label=""
        placeholder="Inserisci indirizzo mail"
        size="large"
        value={email}
        onChange={setEmail}
        style={{ marginBottom: '50px' }}
      />
      <Link to="/login" text="Torna al login" />
      <Button
        text="Avanti"
        radius={4}
        loading={status === 'loading'}
        style={{ width: '50%', marginTop: '30px' }}
      />
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 20vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link as _Link } from 'react-router-dom'
import { ThemeContext, ColorName, Theme } from '@duckma/react-ds'

type Props = {
  to: string
  text: string
  color?: ColorName
  style?: {}
}

export const Link = ({ to, text, color = 'primary100', style }: Props) => {
  const theme = useContext(ThemeContext) as Theme

  return (
    <StyledLink to={to} color={theme[color]} style={style}>
      {text}
    </StyledLink>
  )
}

type StyledProps = { color: string }
const StyledLink = styled(_Link)<StyledProps>`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.color};
  :visited {
    color: ${(props) => props.color};
  }
  :hover {
    opacity: 0.8;
  }
`

import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Button, Input } from '@duckma/react-ds'
import _ from 'lodash'

import { ControlledField, ControlledForm } from '../../../components/ControlledForm'
import { useRequest } from '../../../hooks/useRequest'
import { api, UpdateUser } from '../../../data/api'
import { requiredValidator } from '../../../utils/validators'
import { dateFormatter } from '../../../utils/formatters'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { useDirty } from '../../../hooks/useDirty'
import { User } from '../../../data/models'
import { PasswordField } from '../../../components/PasswordField'

export const ClientDataTab: React.FC<{ user: User }> = ({ user }) => {
  const [getServices, { data: servicesData }] = useRequest(api.getUserService)
  const [updateUser, { status: updateStatus }] = useRequest(api.updateUser)
  const [updateUserPassword, { status: updatePasswordStatus }] = useRequest(api.updateUserPassword)
  const [values, setValues] = useState<Partial<UpdateUser>>({})
  const [valid, setValid] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [validPassword, setValidPassword] = useState(true)

  useEffect(() => {
    getServices({ id: user.id })
  }, [getServices, user.id])

  const initialValues = useMemo(
    () =>
      ({
        ..._.omit(user, ['subscription', 'phone_numbers', 'contacts']),
        ...(servicesData ?? {}),
      } as { [key: string]: string | undefined }),
    [user, servicesData]
  )
  const onChange = useCallback((v:any, valid:any) => {
    setValues(v)
    setValid(valid)
  }, [])

  useDirty(values, initialValues, ['first_name', 'last_name', 'city'])
  useSuccessToast(updateStatus, 'Dati modificati con successo')
  useSuccessToast(updatePasswordStatus, 'Password modificata con successo')
  const passwordOnChange = useCallback((v: any, valid: any) => {
    setNewPassword(v)
    setValidPassword(valid)
  }, [])

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault()
        if (newPassword !== '') {
          updateUserPassword({ id: user.id, password: newPassword })
        }
        if (
          values.last_name !== initialValues.last_name ||
          values.first_name !== initialValues.first_name ||
          values.city !== initialValues.city
        ) {
          updateUser({
            id: user.id,
            body: _.pick(values, ['first_name', 'last_name', 'city', 'title']) as NonNullable<
              UpdateUser
            >,
          })
        }
      }}
    >
      <FieldsContainer>
        {servicesData && (
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <ControlledField
              name="titolo"
              fieldName="title"
              validator={requiredValidator()}
              disabled
            />
            <ControlledField name="nome" fieldName="first_name" validator={requiredValidator()} />
            <ControlledField name="cognome" fieldName="last_name" validator={requiredValidator()} />
            <Input
              disabled
              name="numero-di-telefono"
              value={user.phone_numbers?.find((phone) => phone.type === 'mobile')?.number ?? ''}
            />
            <ControlledField name="città" fieldName="city" validator={requiredValidator()} />
            <ControlledField name="indirizzo-email" fieldName="email" disabled />
            <PasswordField value={newPassword} onChange={passwordOnChange} />
            <ControlledField
              name="Registrato"
              fieldName="created_at"
              disabled
              formatter={dateFormatter}
            />
            <ControlledField fieldName="activations" name="attivazioni" disabled />
            <ControlledField fieldName="total_used_seconds" name="minuti-attivati" disabled />
            <ControlledField fieldName="call_seconds_used" name="minuti-reali" disabled />
            <ControlledField fieldName="handled_calls" name="call-ricevute" disabled />
            <ControlledField fieldName="lost_calls" name="call-perse" disabled />
            <ControlledField
              fieldName="last_activity"
              name="ultima-attività"
              disabled
              formatter={dateFormatter}
            />
            <Input
              name="sottoscrizione"
              disabled
              value={`${
                user.subscription.is_active ? 'Piano attivo' : 'Piano scaduto'
              } - ${_.capitalize(user.subscription.plan)}`}
            />
          </ControlledForm>
        )}
      </FieldsContainer>
      <div style={{ flexGrow: 1 }} />
      <FooterContainer>
        <div style={{ flexGrow: 1 }} />
        <Button
          text="Salva"
          radius={4}
          disabled={!valid || !validPassword}
          loading={updateStatus === 'loading'}
          style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
        />
      </FooterContainer>
    </FormContainer>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
`

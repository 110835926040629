import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, DateTimePicker, Button } from '@duckma/react-ds'
import it from 'date-fns/locale/it'
import moment from 'moment'
import { AvailabilitySlot, Period, Event } from '../../../data/models'
import _ from 'lodash'

const initialAppointment = {
  period: {
    from: moment().format(),
    to: moment().format(),
  },
}
const dayOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
export const AddAppointment: React.FC<{
  open: boolean
  period: Period | undefined
  user: string
  callId: string | undefined
  avaiability: AvailabilitySlot[]
  appointments: Event[]
  onClose: () => void
  onConfirm: (period: { from: string | Date; to: string | Date; validDate: boolean }) => void
}> = ({ open, period, onClose, avaiability, appointments, onConfirm }) => {
  const [appointment, setAppointment] = useState(initialAppointment)
  const [errorFromText, setErrorFromText] = useState('')
  const [validFrom, setValidFrom] = useState(true)
  moment().format('it')
  useEffect(() => {
    setAppointment({
      ...initialAppointment,
      period: period || {
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      },
    })
  }, [period])

  const checkAvailability = useCallback(() => {
    let avail = false
    const todayAvaiability = (_.filter(avaiability, {
      day: dayOfWeek[moment(appointment.period.from).weekday()],
    }) as unknown) as AvailabilitySlot[]

    todayAvaiability.forEach((av) => {
      if (
        moment(appointment.period.from)
          .set('hour', moment(av.start_date).get('hour'))
          .set('minute', moment(av.start_date).get('minute'))
          .isSameOrBefore(moment(appointment.period.from)) &&
        moment(appointment.period.from)
          .set('hour', moment(av.end_date).get('hour'))
          .set('minute', moment(av.end_date).get('minute'))
          .isSameOrAfter(moment(appointment.period.from))
      ) {
        avail = true
      }
    })
    setErrorFromText("Utente non disponibile nell'orario indicato")
    return avail
  }, [appointment.period, avaiability])

  const checkAppointments = useCallback(() => {
    let todayAppoitments: Event[] = _.filter(
      _.filter(
        appointments,
        (date) =>
          moment(date.period.from).dayOfYear() === moment(appointment.period.from).dayOfYear()
      ),
      (date) => moment(date.period.to).isSameOrAfter(moment(appointment.period.from))
    )

    todayAppoitments = _.filter(
      todayAppoitments,
      (date) =>
        moment(date.period.from).isSameOrAfter(moment(appointment.period.from)) &&
        moment(date.period.to).isSameOrBefore(moment(appointment.period.to))
    )

    if (todayAppoitments.length !== 0) {
      setErrorFromText('Utente già impegnato in altro appuntamento')
    }
    return todayAppoitments.length === 0
  }, [appointment.period, appointments])

  const checkCoerency = useCallback(() => {
    if (moment(appointment.period.from).isSameOrAfter(appointment.period.to)) {
      setErrorFromText('Data di inizio successiva alla data di fine')
      return false
    }
    return true
  }, [appointment.period])

  useEffect(() => {
    const chkAv = checkAvailability()

    if (!chkAv) {
      setValidFrom(false)
      return
    }
    const chkCo = checkCoerency()

    if (!chkCo) {
      setValidFrom(false)
      return
    }
    const chkAp = checkAppointments()

    if (!chkAp) {
      setValidFrom(false)
      return
    }
    setValidFrom(true)
  }, [appointment.period, checkAppointments, checkAvailability, checkCoerency])

  return (
    <StyledModal open={open} onClose={() => onClose()} title="Aggiungi appuntamento" color="white">
      <Content
        autoComplete="off"
        onSubmit={async (ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          onConfirm({ ...appointment.period, validDate: validFrom })
        }}
      >
        {appointment && (
          <FormGrid>
            <DateTimePicker
              name="data-e-ora-di-inizio"
              locale={it}
              selected={new Date(appointment.period.from)}
              showTimeSelect
              onChange={(from) =>
                setAppointment({
                  ...appointment,
                  period: { ...appointment.period, from: moment(from).format() },
                })
              }
              valid={validFrom}
              errorText={errorFromText}
            />
            <DateTimePicker
              locale={it}
              name="data-e-ora-di-fine"
              selected={new Date(appointment.period.to)}
              showTimeSelect
              onChange={(to) =>
                setAppointment({
                  ...appointment,
                  period: { ...appointment.period, to: to.toISOString() },
                })
              }
            />
          </FormGrid>
        )}
        <ButtonFooter>
          <div />
          <Button text="Crea appointmento" color="primary" radius={4} disabled={!validFrom} />
        </ButtonFooter>
      </Content>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 25vh;
  left: 20vw;
  right: 20vw;
  bottom: 25vh;
  outline: none;
  border-radius: 10px;
  > * {
    z-index: 2;
  }
`

const Content = styled.form`
  box-sizing: border-box;
  width: 100%;
  padding: 50px 50px 20px 50px;

  justify-content: space-between;
  height: calc(100% - 64px);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
`

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 50px;
  grid-row-gap: 20px;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  > :nth-child(1) {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }
  > :nth-child(n + 2) {
    flex-grow: 0;
    flex-basis: 35%;
  }
`

import { Button, Input, Subtitle } from '@duckma/react-ds'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import 'moment/locale/it'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { calendarLocalization, calendarFormats } from '../../i18n/localizations'
import { AddAppointment } from './components/AddAppointment'
import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { AvailabilitySlot, Message, Event, Period } from '../../data/models'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { useSuccessToast } from '../../hooks/useSuccessToast'

moment.locale('it')

type CalendarEvent = { allDay?: boolean; title: string; start: Date; end: Date; resource: Event }

const emptyMessage: Message = {
  id: '',
  body: '',
  created_at: '',
  sender_name: '',
  sender_phone_number: '',
}

export const CallWithClient = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [getCall, { data: callData }] = useRequest(api.getCall)
  const [endCall, { status: endCallStatus }] = useRequest(api.endCall)

  const [getUserAppointments, { data: appointments, status: appointmentStatus }] = useRequest(
    api.getAppointments
  )
  const [setAppointment, { status: setAppointmentStatus }] = useRequest(api.setAppointment)
  const [getAvailability, { status: availabilityStatus, data: availabilityData }] = useRequest(
    api.getAvailability
  )

  const [message, setMessage] = useState<Message>(emptyMessage)
  const [callTimer, setCallTimer] = useState(0)

  const [currentRange, setCurrentRange] = useState<{ from: Date; to: Date }>()
  const [showModal, setShowModal] = useState(false)
  const [currentSlot, setCurrentSlot] = useState<Period>()
  const [appointmentEvents, setAppointmentEvents] = useState<Event[]>([])
  const [events, setEvents] = useState<Event[]>([])

  useEffect(() => {
    const id = setTimeout(() => setCallTimer(callTimer + 1), 1000)
    return () => clearInterval(id)
  })

  useEffect(() => {
    if (endCallStatus === 'success') {
      navigate('/dashboard')
    }
  }, [endCallStatus, navigate])

  useSuccessToast(setAppointmentStatus, 'Appuntamento registrato con successo')

  useEffect(() => {
    if (setAppointmentStatus === 'success' && callData && currentRange) {
      getUserAppointments({
        id: callData.called_id,
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate(),
      })
    }
  }, [setAppointmentStatus, currentRange, callData, getUserAppointments])

  const calcUnavailabilityPerRange = async (
    slots: AvailabilitySlot[],
    range: Date[] | { start: string | Date; end: string | Date }
  ) => {
    let [firstRangeDay, lastRangeDay] = Array.isArray(range)
      ? [moment(range[0]), moment(range[6])]
      : [moment(range.start), moment(range.end)]

    setCurrentRange({ from: firstRangeDay.toDate(), to: lastRangeDay.toDate() })

    if (lastRangeDay.diff(firstRangeDay, 'days') > 7) {
      return
    }

    const availability: AvailabilitySlot[][] = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].map((day) =>
      slots
        .filter((d) => d.day === day)
        .sort((a, b) => moment(a.start_date).unix() - moment(b.start_date).unix())
    )

    const rangePeriods = availability.flatMap(
      (dailyAvailableSlots, weekdayIndex) =>
        [...dailyAvailableSlots, null].reduce<{
          previousSlotEnd: moment.Moment
          unavailableSlots: { from: string; to: string }[]
        }>(
          (acc, currentAvailableSlot) => ({
            previousSlotEnd: currentAvailableSlot
              ? acc.previousSlotEnd
                  .clone()
                  .set('hour', moment(currentAvailableSlot.end_date).hour())
                  .set('minute', moment(currentAvailableSlot.end_date).minute())
              : moment(),
            unavailableSlots: [
              ...acc.unavailableSlots,
              {
                from: acc.previousSlotEnd.clone().format(),
                to: currentAvailableSlot
                  ? acc.previousSlotEnd
                      .clone()
                      .set('hour', moment(currentAvailableSlot.start_date).hour())
                      .set('minute', moment(currentAvailableSlot.start_date).minute())
                      .format()
                  : acc.previousSlotEnd.clone().set('hour', 23).set('minutes', 59).format(),
              },
            ],
          }),
          {
            previousSlotEnd: moment(firstRangeDay)
              .startOf('isoWeek')
              .add(weekdayIndex, 'd')
              .set('hour', 0)
              .set('minutes', 0),
            unavailableSlots: [],
          }
        ).unavailableSlots
    )

    setEvents(
      rangePeriods.map((period) => ({
        period,
        title: '',
        type: { name: 'notAvailable', color: 'rgba(221,221,221,0.8)' },
      }))
    )
  }

  const addAppoinmentEvents = async (appointmentsEvent: Period[]) => {
    let appointments: Event[] = []
    appointmentsEvent.forEach((event) => {
      appointments.push({
        type: { name: 'appountemt', color: '#3B7B95' },
        title: 'Appointment',
        period: event,
      })
    })
    setAppointmentEvents([...appointments])
  }

  const dataObject = useMemo(() => {
    if (callData) {
      setMessage({
        ...callData?.message,
        sender_phone_number: callData?.caller_phone_number.replace('+39', '0039') ?? '',
      })
    }
    return { ...callData }
  }, [callData])

  useEffect(() => {
    getCall({ id })
  }, [getCall, id])

  useEffect(() => {
    if (availabilityStatus === 'success' && availabilityData) {
      calcUnavailabilityPerRange(availabilityData, {
        start: moment().weekday(0).toDate(),
        end: moment().weekday(0).add(6).toDate(),
      })
    }
  }, [availabilityStatus, availabilityData])

  useEffect(() => {
    if (appointmentStatus === 'success' && appointments) {
      addAppoinmentEvents(appointments)
    }
  }, [appointments, appointmentStatus])

  useEffect(() => {
    if (callData) {
      getUserAppointments({
        id: callData.called_id,
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate(),
      })
      getAvailability({ id: callData.called_id })
    }
  }, [getAvailability, callData, getUserAppointments])

  const seconds = ('00' + Math.floor((callTimer % 3600) % 60)).slice(-2)
  const minutes = ('00' + Math.floor((callTimer % 3600) / 60)).slice(-2)
  const hours = callTimer > 3600 ? ('00' + Math.floor(callTimer / 3600)).slice(-2) + ':' : ''

  return (
    <Container>
      {showModal && (
        <AddAppointment
          open={showModal}
          user={callData?.message.id ?? ''}
          period={currentSlot}
          callId={id}
          avaiability={availabilityData ?? []}
          appointments={appointmentEvents}
          onClose={() => {
            setShowModal(false)
          }}
          onConfirm={(period: { from: string | Date; to: string | Date; validDate: boolean }) => {
            if (period.validDate) {
              setAppointment({ appointment: period, id: message.id })
            }
            setShowModal(false)
          }}
        />
      )}
      {callData?.instructions && (
        <InstructionsBox>
          <Subtitle>Istruzioni</Subtitle>
          <Text>{callData.instructions}</Text>
        </InstructionsBox>
      )}
      <TimerBox>
        <TimerText time={callTimer}>{`${hours}${minutes}:${seconds}`}</TimerText>
      </TimerBox>
      <Separator />
      <FormContainer>
        <ControlledForm initialValues={callData ? (dataObject as any) : null}>
          <ControlledField disabled name="Utente in chiamata" fieldName="caller_phone_number" />
          <ControlledField disabled name="Cliente" fieldName="called_name" />
        </ControlledForm>
      </FormContainer>
      <Separator />
      <FormContainer>
        <SectionSubtitle>Lascia messaggio</SectionSubtitle>

        <Input
          name="Nome del chiamante"
          value={message?.sender_name}
          onChange={(sender_name) => setMessage((message) => ({ ...message, sender_name }))}
        />
        <Input
          name="Numero del chiamante"
          description="Prima del numero sempre 0039"
          value={message.sender_phone_number}
          onChange={(sender_phone_number) =>
            setMessage((message) => ({ ...message, sender_phone_number }))
          }
        />

        <Input
          style={{ flexBasis: '100%', marginBottom: '5px' }}
          placeholder="Inserisci messaggio"
          name="Messaggio"
          value={message.body}
          onChange={(body) => setMessage((message) => ({ ...message, body }))}
          rows={5}
        />
        <div>
          <DescriptionText>Policy text</DescriptionText>
          <DescriptionText>Chiedere il nome di chi sta chiamando</DescriptionText>
          <DescriptionText>
            Considerare la chiamata come "urgente" se si tratta di: incidenti, lutto, ecc.
          </DescriptionText>
        </div>
      </FormContainer>

      {callData?.is_premium && (
        <AppointmentBox>
          <SectionSubtitle style={{ flexBasis: '50%', marginTop: '15px' }}>
            Calendario appuntamenti
          </SectionSubtitle>
          <AppointmentButton
            size="small"
            onClick={() => setShowModal(true)}
            text="Aggiungi appuntamento"
            radius={5}
          />
          <div
            style={{
              flexBasis: '100%',
              backgroundColor: '#fff',
              borderRadius: '10px',
              padding: '20px',
            }}
          >
            <StyledCalendar<CalendarEvent, Event>
              events={[...events, ...appointmentEvents].map((ev) => ({
                title: ev.title,
                start: new Date(ev.period.from),
                end: new Date(ev.period.to),
                resource: ev,
              }))}
              defaultView="week"
              localizer={momentLocalizer(moment)}
              formats={calendarFormats}
              onRangeChange={(range) => {
                calcUnavailabilityPerRange(availabilityData || [], range)
              }}
              messages={calendarLocalization}
              culture="it"
              onSelectSlot={(range) => {
                setShowModal(true)
                setCurrentSlot({
                  from: moment(range.start).format(),
                  to: moment(range.end).format(),
                })
              }}
              components={{
                week: { event: WeekEvent },
                month: { event: MonthEvent },
                day: { event: DayEvent },
                agenda: { event: AgendaEvent },
              }}
              selectable
            />
          </div>
        </AppointmentBox>
      )}

      <ConfirmBox>
        <ConfirmButton
          radius={5}
          text="Chiudi"
          onClick={async (e) => {
            e.preventDefault()
            setMessage((message) => ({
              ...message,
              sender_phone_number: message.sender_phone_number.replace('0039', '+39'),
            }))
            endCall({ id: message.id, body: message })
          }}
        />
        <DescriptionText style={{ marginTop: '15px' }}>
          Premere chiudi per confermare
        </DescriptionText>
      </ConfirmBox>
    </Container>
  )
}

const getColor = (event: CalendarEvent) => event?.resource.type.color || '#ABCDAB'
const MonthEvent: React.FC<{ event: CalendarEvent }> = ({ event }) => (
  <EventWrapper
    style={{ width: event.resource.type.name === 'notAvailable' ? '100%' : '90%' }}
    backgroundColor={getColor(event)}
    radiusTop
  >
    <em>
      {' '}
      {event.resource.type.name === 'notAvailable'
        ? ''
        : `${moment(event?.start).format('HH:mm')} - ${moment(event?.end).format('HH:mm')}`}
    </em>
  </EventWrapper>
)
const WeekEvent = MonthEvent
const DayEvent: React.FC<{ event: CalendarEvent }> = ({ event }) => (
  <EventWrapper backgroundColor={getColor(event)}>
    <em>{event?.start.getHours()}</em>
  </EventWrapper>
)
const AgendaEvent: React.FC<{ event: CalendarEvent }> = ({ event }) => (
  <EventWrapper backgroundColor="#FFF">
    <em>
      {' '}
      {event.resource.type.name === 'notAvailable'
        ? ''
        : `${moment(event?.start).format('HH:mm')} - ${moment(event?.end).format('HH:mm')}`}
    </em>
  </EventWrapper>
)

const EventWrapper = styled.div<{ backgroundColor: string; radiusTop?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border-top-left-radius: ${(props) => (props.radiusTop ? 4 : 0)}px;
  border-top-right-radius: ${(props) => (props.radiusTop ? 4 : 0)}px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 5px;
  em {
    max-height: 100%;
  }
`

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 50px;
`

const Separator = styled.div`
  background-color: #fff;
  height: 10px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`
const InstructionsBox = styled.div`
  width: 100%;
  padding: 0 50px;
`

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  display: flex;
  align-items: center;
`
const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const SectionSubtitle = styled(Subtitle)`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
  flex-basis: 100%;
`

const TimerBox = styled.div`
  position: absolute;
  right: 50px;
  top: 45px;
`

const TimerText = styled.p<{ time: number }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: ${(props) => (props.time > 60 ? '#FB5E5E' : '#1F596E')};
`

const DescriptionText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #828894;
  margin: 2px;
`

const AppointmentBox = styled.div`
  margin: 50px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
`

const AppointmentButton = styled(Button)`
  width: fit-content;
  min-width: 200px;
  padding: 2px 10px;
  margin-left: auto;
  height: 40px;
`

const ConfirmBox = styled.div`
  width: 100%;
  margin: 20px 50px;
  > * {
    float: right;
  }
`
const ConfirmButton = styled(Button)`
  width: fit-content;
  margin-left: 20px;
  padding: 15px;
`

const StyledCalendar = (styled(Calendar)`
  height: calc(100vh - 20px);
  overflow: scroll;
  min-width: 70vw;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  .rbc-event {
    padding: 0;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0);
  }
  .rbc-events-container {
    margin-right: 0;
  }
  .rbc-event-label {
    padding-left: 5px;
    background-color: #ddd;
    color: #222;
    display: none;
    width: 100%;
  }
` as unknown) as typeof Calendar

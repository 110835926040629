import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { api } from '../../data/api'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Ordering, Tag, ColorName, Button, Paginator } from '@duckma/react-ds'
import { useRequest } from '../../hooks/useRequest'
import { VoucherGroup, VoucherType } from '../../data/models'
import { Column, Subtitle } from '@duckma/react-ds'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { dateTimeFormatter } from '../../utils/formatters'

const tags: { [key in VoucherType]: { color: ColorName; label: string } } = {
  welfare: { color: 'primary100', label: 'Azienda' },
  percentage: { color: 'primary100', label: 'Sconto' },
  fixed_amount: { color: 'primary100', label: 'Sconto' },
}

export const VoucherGroupsPage = () => {
  const navigate = useNavigate()
  const [getVoucherGroups, { status, data }] = useRequest(api.getVoucherGroups)
  const [getVoucherGroupCSV, { status: csvStatus }] = useRequest(api.getVoucherGroupCSV)
  const [updateVoucherGroup, { data: updateData }] = useRequest(api.updateVoucherGroup)

  const [ordering, setOrdering] = useState<Ordering<VoucherGroup>>({
    key: 'id',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getVoucherGroupsWithParams = useCallback(
    () =>
      getVoucherGroups({
        order_by: ordering.key,
        order_direction: ordering.direction,
        search_text: search,
        page,
        page_size: 10,
      }),
    [getVoucherGroups, ordering, page, search]
  )

  useEffect(() => {
    if (updateData !== null) {
      getVoucherGroups({
        order_by: ordering.key,
        order_direction: ordering.direction,
        search_text: search,
        page,
        page_size: 10,
      })
    }
  }, [updateData, page, ordering, search, getVoucherGroups])

  useEffect(() => {
    getVoucherGroupsWithParams()
  }, [getVoucherGroupsWithParams])

  const columns = useMemo(
    () =>
      [
        { key: 'name', name: 'Name', orderable: true },
        {
          key: 'type',
          name: 'Tipologia',
          render: ({ type }) =>
            type ? <Tag text={tags[type].label} color={tags[type].color} /> : '',
        },
        {
          key: 'valid_from',
          name: 'Inizio validità',
          render: ({ valid_from }) => dateTimeFormatter(valid_from),
        },
        {
          key: 'valid_to',
          name: 'Fine validità',
          render: ({ valid_to }) => dateTimeFormatter(valid_to),
        },
        { key: 'quantity', name: 'Quantità' },
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
          render: ({ id, name }) => (
            <Actions>
              <Button
                size="icon"
                iconLeft="pen-alt"
                color="primary"
                radius={24}
                onClick={() => {
                  navigate('/vouchers/' + id)
                }}
              />
              <Button
                size="icon"
                iconLeft="clock"
                color="danger"
                radius={24}
                onClick={() => {
                  updateVoucherGroup({ id, body: { valid_to: new Date().toISOString() } })
                }}
              />
              <Button
                size="icon"
                iconLeft="arrow-bottom"
                color="secondary"
                radius={24}
                disabled={csvStatus === 'loading'}
                onClick={() => {
                  getVoucherGroupCSV({ id, fileName: `voucher_codes_${name}.csv` })
                }}
              />
            </Actions>
          ),
        },
      ] as Column<VoucherGroup>[],
    [csvStatus, getVoucherGroupCSV, navigate, updateVoucherGroup]
  )

  return (
    <Container>
      <SearchField
        style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
        onSearch={(search) => {
          if (page !== 0) {
            setPage(0)
          }
          setSearch(search)
        }}
      />
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun gruppo voucher trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`

import React, { useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Button, Radios } from '@duckma/react-ds'
import _ from 'lodash'

import { ControlledField, ControlledForm } from '../../../components/ControlledForm'
import { useRequest } from '../../../hooks/useRequest'
import { api, UpdateUser } from '../../../data/api'
import { requiredValidator } from '../../../utils/validators'
import { dateFormatter } from '../../../utils/formatters'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { useDirty } from '../../../hooks/useDirty'
import { Role, User } from '../../../data/models'
import { storage } from '../../../data/storage'

const roleChoices: { key: Partial<Role>; label: string; canCreateIf: Role[] }[] = [
  { canCreateIf: ['super_admin', 'admin'], key: 'admin', label: 'Admin' },
  {
    canCreateIf: ['super_admin', 'admin', 'operator_manager'],
    key: 'operator',
    label: 'Operatore centralino',
  },
  {
    canCreateIf: ['super_admin', 'admin', 'operator_manager'],
    key: 'operator_manager',
    label: 'Manager operatori',
  },
]

export const AdminDetail: React.FC<{ user: User }> = ({ user }) => {
  const currentRole = storage.getUser()?.role
  const [updateUser, { status: updateStatus }] = useRequest(api.updateUser)

  const [values, setValues] = useState<Partial<UpdateUser>>({})
  const [valid, setValid] = useState(true)

  const initialValues = useMemo(
    () => _.pick(user, ['first_name', 'last_name', 'email', 'created_at']),
    [user]
  )
  const onChange = useCallback((v:any, valid: any) => {
    setValues(v)
    setValid(valid)
  }, [])

  useDirty(values, initialValues, ['first_name', 'last_name'])
  useSuccessToast(updateStatus, 'Dati modificati con successo')

  return (
    <Container>
      <Radios
        left
        disabled
        choices={roleChoices.filter((rc) => rc.canCreateIf.includes(currentRole ?? 'user'))}
        value={user.role || 'admin'}
        onChange={() => {}}
      />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          updateUser({
            id: user.id,
            body: _.pick(values, ['first_name', 'last_name']) as NonNullable<UpdateUser>,
          })
        }}
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <ControlledField name="nome" fieldName="first_name" validator={requiredValidator()} />
            <ControlledField name="cognome" fieldName="last_name" validator={requiredValidator()} />
            <ControlledField
              name="data-di-iscrizione"
              fieldName="created_at"
              disabled
              formatter={dateFormatter}
            />
            <ControlledField name="indirizzo-email" fieldName="email" disabled />
          </ControlledForm>
        </FieldsContainer>
        <div style={{ flexGrow: 1 }} />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={!valid}
            loading={updateStatus === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: calc(100% - 20px);
  padding-bottom: 20px;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 55px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`

import React, { useContext } from 'react'
import styled from 'styled-components'
import { Label, Description, ThemeContext, Theme } from '@duckma/react-ds'
import AsyncSelect, { AsyncProps } from 'react-select/async'

import { InputProps } from '@duckma/react-ds/dist/components/Input'
import { GroupBase } from 'react-select'

export type SelectEntry = { value: string; label: string }
type Props = Omit<InputProps, 'value' | 'onChange'> & {
  value?: SelectEntry | null
  onChange?: (value: SelectEntry | null) => void
  getItemsPromise?: (search: string) => Promise<SelectEntry[]>
  emptyMessage?: string
  loadingMessage?: string
}

export const Select: React.FC<Props> = ({
  value,
  onChange,
  label = 'Selettore',
  disabled,
  placeholder = 'Seleziona un elemento',
  emptyMessage = 'Nessun elemento trovato',
  loadingMessage = 'Caricamento...',
  getItemsPromise,
  valid = true,
  errorText,
}) => {
  const theme = useContext(ThemeContext) as Theme

  return (
    <div style={{ width: '100%' }}>
      <Label text={label} style={{ marginBottom: '5px' }} color="black" />
      <StyledSelect
        isDisabled={disabled}
        placeholder={placeholder}
        noOptionsMessage={() => emptyMessage}
        loadingMessage={() => loadingMessage}
        defaultOptions
        loadOptions={getItemsPromise}
        value={value}
        onChange={(newValue) => onChange && onChange(newValue)}
        valid={valid}
        borderColor={theme['gray100']}
        errorBorderColor={theme['danger100']}
        styles={{
          control: (base: any) => ({
            ...base,
            height: '34px',
            'min-height': '34px',
            'align-items': 'flex-start',
            'padding-top': '1px',
          }),
        }}
      />
      {!valid && (
        <Description
          text={errorText}
          color="danger100"
          style={{ marginTop: '5px', fontWeight: 400 }}
        />
      )}
    </div>
  )
}

type CustomSelectProps = AsyncProps<SelectEntry, false, GroupBase<SelectEntry>>

const StyledSelect = styled(AsyncSelect)<{
  valid: boolean
  borderColor: string
  errorBorderColor: string
} & CustomSelectProps>`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  > div {
    border: 1px solid ${(props) => (props.valid ? props.borderColor : props.errorBorderColor)};
    :hover {
      border: 1px solid ${(props) => (props.valid ? props.borderColor : props.errorBorderColor)};
    }
    border-radius: 2px;
  }
`

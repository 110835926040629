import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { useParams } from 'react-router-dom'
import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { Voucher, VoucherType } from '../../data/models'
import {
  Button,
  ColorName,
  Column,
  Ordering,
  Paginator,
  Subtitle,
  Table,
  Tag,
} from '@duckma/react-ds'
import { dateTimeFormatter } from '../../utils/formatters'
import { SkeletonTable } from '../../components/SkeletonTable'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import moment from 'moment'

const validityValues = {
  '3_months': '3 mesi',
  '6_months': '6 mesi',
  '9_months': '9 mesi',
}

const statusTagValues = {
  active: { color: 'success100', label: 'Attivo' },
  inactive: { color: 'gray100', label: 'Non attivo' },
  used: { color: 'primary100', label: 'Consumato' },
  expired: { color: 'gray100', label: 'Scaduto' },
}

export const VoucherGroupDetail = () => {
  const { id } = useParams<{ id:string | undefined}>()
  const [getVoucherGroup, { status, data }] = useRequest(api.getVoucherGroup)
  const [invalidateVoucher, { status: updateStatus, data: updateData }] = useRequest(
    api.updateVoucher
  )

  const [ordering, setOrdering] = useState<Ordering<Voucher>>({
    key: 'id',
    direction: 'asc',
  })
  const [page, setPage] = useState<number>(0)

  useEffect(() => {
    if (updateData != null) {
      getVoucherGroup({
        id,
        order_by: ordering.key,
        order_direction: ordering.direction,
        page,
        page_size: 10,
      })
    }
  }, [updateData, getVoucherGroup, id, ordering, page])

  useSuccessToast(updateStatus, 'Dati modificati con successo')

  const getVoucherGroupsWithParams = useCallback(
    () =>
      getVoucherGroup({
        id,
        order_by: ordering.key,
        order_direction: ordering.direction,
        page,
        page_size: 10,
      }),
    [getVoucherGroup, id, ordering.direction, ordering.key, page]
  )

  useEffect(() => {
    if (status === 'idle') {
      getVoucherGroup({ id })
    }
  }, [status, getVoucherGroup, id])

  useEffect(() => {
    getVoucherGroupsWithParams()
  }, [getVoucherGroupsWithParams])

  const dataObject = useMemo(
    () => ({
      name: data?.name,
      type: data?.type === VoucherType.PERCENTAGE ? 'Sconto' : 'Azienda',
      quantity: String(data?.quantity),
      valid_to: data?.valid_to,
      validity: validityValues[data?.validity ?? '3_months'],
      plan: data?.plan,
      percentage: data?.amount?.toString(),
    }),
    [data]
  )

  const columns = useMemo(
    () =>
      [
        { key: 'code', name: 'Codice', orderable: true },
        {
          key: 'used',
          name: 'Stato',
          render: (tag) => {
            const status = tag.used
              ? 'used'
              : moment(tag.valid_from).isAfter(moment())
              ? 'inactive'
              : moment(tag.valid_to).isBefore(moment())
              ? 'expired'
              : 'active'

            const { color, label } = statusTagValues[status]

            return <Tag color={color as ColorName} text={label} />
          },
          orderable: true,
        },
        {
          key: 'valid_from',
          name: 'Inizio validità',
          render: ({ valid_from }) => dateTimeFormatter(valid_from),
          orderable: true,
        },
        {
          key: 'valid_to',
          name: 'Fine validità',
          render: ({ valid_to }) => dateTimeFormatter(valid_to),
          orderable: true,
        },
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
          render: (voucher) => (
            <Actions>
              <Button
                size="icon"
                iconLeft="trash"
                color="danger"
                radius={24}
                onClick={() => {
                  invalidateVoucher({
                    codeId: voucher.id,
                    body: { valid_to: new Date().toISOString() },
                  })
                }}
              />
            </Actions>
          ),
        },
      ] as Column<Voucher>[],
    [invalidateVoucher]
  )

  return (
    <div>
      <Container>
        <FormContainer>
          <FieldsContainer>
            {data && (
              <ControlledForm initialValues={dataObject}>
                <ControlledField disabled name="nome" fieldName="name" />
                <ControlledField disabled name="quantità" fieldName="quantity" />
                <ControlledField disabled name="tipologia" fieldName="type" />
                {data.type === VoucherType.WELFARE && (
                  <>
                    <ControlledField disabled name="Validità" fieldName="validity" />
                    <ControlledField disabled name="piano" fieldName="plan" />
                  </>
                )}
                {data.type !== VoucherType.WELFARE && (
                  <ControlledField disabled name="percentuale" fieldName="percentage" />
                )}
              </ControlledForm>
            )}
          </FieldsContainer>
          <div style={{ flexGrow: 1 }} />
        </FormContainer>
      </Container>
      <SectionDivider />
      <Container>
        {status !== 'loading' ? (
          <Table
            columns={columns}
            records={data ? data.vouchers.items : []}
            order={ordering}
            onOrder={setOrdering}
          />
        ) : (
          <SkeletonTable />
        )}
        {status === 'success' && data && data.vouchers.items.length > 0 && (
          <Paginator
            style={{ marginTop: '75px', marginBottom: '75px' }}
            currentPage={page}
            totalPages={data.vouchers.pagination.total_pages}
            onPageSelect={setPage}
          />
        )}
        {status === 'success' && data && data.vouchers.items.length === 0 && (
          <Subtitle text="Nessun voucher trovato" color="gray100" />
        )}
      </Container>
    </div>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: calc(100% - 20px);
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const SectionDivider = styled.div`
  height: 12px;
  width: 100%;
  background-color: #fff;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import {
  Column,
  Subtitle,
  Table,
  Ordering,
  Tag,
  ColorName,
  Button,
  Paginator
} from '@duckma/react-ds'
import toast from 'react-hot-toast'

import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { User, Role, SubscriptionPlan } from '../../data/models'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { Select } from '../../components/Select'

const tags: { [key in Role]: { color: ColorName; label: string } } = {
  user: { color: 'primary100', label: 'Mysecretary' },
  operator: { color: 'primary100', label: 'Operatore centralino' },
  operator_manager: { color: 'primary100', label: 'Manager operatori' },
  admin: { color: 'primary100', label: 'Admin' },
  super_admin: { color: 'primary100', label: 'Super Admin' },
}

export const UsersPage = () => {
  const navigate = useNavigate()
  const [getUsers, { status, data }] = useRequest(api.getUsers)
  const [deleteUser, { data: deleteData }, resetDelete] = useRequest(api.deleteUser)

  const [ordering, setOrdering] = useState<Ordering<User>>({
    key: 'first_name',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [subscriptionPlanFilter, setSubscriptionPlanFilter] = useState<SubscriptionPlan | undefined>(undefined)
  const [roleFilter, setRoleFilter] = useState<Role | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getUsersWithParams = useCallback(
    () =>
      getUsers({
        order_by: ordering.key,
        order_direction: ordering.direction,
        search_text: search,
        ...(roleFilter?.length ? { roles: [roleFilter] } : {}),
        ...(subscriptionPlanFilter?.length ? { plan: subscriptionPlanFilter } : {}),
        page,
        page_size: 10,
      }),
    [getUsers, ordering, page, search, roleFilter, subscriptionPlanFilter]
  )

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  useEffect(() => {
    if (deleteData != null) {
      toast.success('Utente eliminato con successo')
      getUsersWithParams()
      resetDelete()
    }
  }, [deleteData, resetDelete, getUsersWithParams])

  const columns = useMemo(
    () =>
      [
        { key: 'first_name', name: 'Nome', orderable: true },
        { key: 'last_name', name: 'Cognome', orderable: true },
        {
          key: 'role',
          name: 'Ruolo',
          orderable: true,
          render: (user) => <Tag text={tags[user.role].label} color={tags[user.role].color} />,
        },
        {
          key: 'plan',
          name: 'Piano',
          orderable: false,
          render: (user) => {
            switch (user.subscription?.plan) {
              case SubscriptionPlan.FREE: {
                return (<Tag
                  text={'Prova Gratuita'}
                  color={'success100'}
                />)
              }
              case SubscriptionPlan.BASE: {
                return (<Tag
                  text={'Base'}
                  color={'warning100'}
                />)
              }
              case SubscriptionPlan.PREMIUM: {
                return (<Tag
                  text={'Premium'}
                  color={'danger100'}
                />)
              }
              default: {
                return (<></>)
              }
            }
          }
        },
        {
          key: 'subscription_status',
          name: 'Sottoscrizione',
          orderable: false,
          render: (user) =>
            user.role === 'user' ? (
              <Tag
                text={user.subscription?.is_active ? 'Attiva' : 'Scaduta'}
                color={user.subscription?.is_active ? 'success100' : 'danger100'}
              />
            ) : (
              <div />
            ),
        },
        { key: 'email', name: 'Email', orderable: true },
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
          render: (user) => (
            <Actions>
              <Button
                size="icon"
                iconLeft="pen-alt"
                color="primary"
                radius={24}
                onClick={() => {
                  navigate('/users/' + user.id)
                }}
              />
              {user.role !== 'user' && (
                <Button
                  size="icon"
                  iconLeft="trash"
                  color="danger"
                  radius={24}
                  onClick={() => {
                    const conf = window.confirm(
                      `Sei sicuro di voler cancellare ${user.email}, con ruolo "${tags[user.role].label
                      }"? L'azione è irreversibile.`
                    )
                    if (conf) {
                      deleteUser({ id: user.id })
                    }
                  }}
                />
              )}
            </Actions>
          ),
        },
      ] as Column<User>[],
    [deleteUser, navigate]
  )

  return (
    <Container>
      <div style={{
        display: 'flex',
        gap: '16px',
        width: '70%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        justifyItems: 'flex-end',
        alignSelf: 'flex-end',
        alignItems: 'flex-end',
        marginBottom: '35px'
      }}>
        <Select
          label='Ruolo Utente'
          name="user_role"
          onChange={(value) => setRoleFilter(value as unknown as Role)}
          getItemsPromise={async () => ([
            { value: '', label: 'Qualsiasi' },
            { value: 'user', label: 'Utente' },
            { value: 'operator', label: 'Operatore' },
            { value: 'operator_manager', label: 'Manager' },
            { value: 'admin', label: 'Amministratore' },
          ])}
        />
        <Select
          label='Tipo Abbonamento'
          name="subscription_plan"
          onChange={( value ) => setSubscriptionPlanFilter(value as unknown as SubscriptionPlan)}
          getItemsPromise={async () => ([
            { value: '', label: 'Qualsiasi' },
            { value: SubscriptionPlan.FREE, label: 'Prova Gratuita' },
            { value: SubscriptionPlan.BASE, label: 'Base' },
            { value: SubscriptionPlan.PREMIUM, label: 'Premium' },
          ])}
        />
        <SearchField
          onSearch={(search) => {
            if (page !== 0) {
              setPage(0)
            }
            setSearch(search)
          }}
        />
      </div>
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun utente trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`

import { useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import toast from 'react-hot-toast'

import { errors } from '../i18n/errors'

export type RequestStatus = 'idle' | 'loading' | 'success' | 'error'
type RequestState<T> = {
  status: RequestStatus
  data: T | null
  error: string | null
}
export const useRequest = <T, P>(fetchFn: (params: P) => Promise<T>) => {
  const [state, setState] = useState<RequestState<T>>({
    status: 'idle',
    data: null,
    error: null,
  })

  const request = useCallback(
    (params: P) => {
      setState((state) => ({ ...state, status: 'loading' }))
      fetchFn(params)
        .then((data: T) => {
          setState({ data, error: null, status: 'success' })
        })
        .catch((error: Error) => {
          const text = _.get(errors, error.message, errors['server_error'])['it']
          setState({ data: null, error: text, status: 'error' })
          toast.error(text)
        })
    },
    [fetchFn]
  )

  return useMemo(
    () =>
      [request, state, () => setState({ data: null, error: null, status: 'idle' })] as [
        (params: P) => void,
        RequestState<T>,
        () => void
      ],
    [request, state]
  )
}

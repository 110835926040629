import React, { useContext, useEffect } from 'react'
import { Tag, ThemeContext, Table, Column, Ordering, Theme } from '@duckma/react-ds'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

import { DigitsWidget as TDigitsWidget, TableWidget as TTableWidget } from '../../data/models'
import { translate } from '../../i18n/dashboard_labels'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'

export const DashboardPage = () => {
  const [getDashboard, { data }] = useRequest(api.getDashboard)

  useEffect(() => {
    getDashboard({})
  }, [getDashboard])

  return (
    <Container>
      {data
        ? _.sortBy(data, ['type', 'items.length']).map((widget) => {
            switch (widget.type) {
              case 'digits':
                return <DigitsWidget key={widget.id} data={widget} />
              case 'table':
                return <TableWidget key={widget.id} data={widget} />
              default:
                return 'Invalid widget type'
            }
          })
        : _.times(6, (i) => <Skeleton key={i} width="100%" height="300px" />)}
    </Container>
  )
}

const DigitsWidget: React.FC<{ data: TDigitsWidget }> = ({ data: { items, props } }) => {
  const theme = useContext(ThemeContext) as Theme
  return (
    <WidgetCard width="29%">
      <WidgetHeader>
        <span>{translate(props.title)}</span>
        <Tag style={{ visibility: 'hidden' }} text={translate(props.period)} />
      </WidgetHeader>
      <DigitsContainer>
        {items.map(({ description, fraction, value }) => (
          <Digit key={description} valueColor={theme['gray100']} descriptionColor={theme['black']}>
            <span>{value ? value : '-'}</span>
            {fraction && <b>/{translate(fraction)}</b>}
            <span>{translate(description)}</span>
          </Digit>
        ))}
      </DigitsContainer>
    </WidgetCard>
  )
}
const TableWidget: React.FC<{ data: TTableWidget }> = ({ data: { items, props } }) => {
  const cols = props.columns.map((key) => ({
    key,
    name: translate(key),
    orderable: false,
  })) as Column<any>[]
  return (
    <WidgetCard width="46%">
      <WidgetHeader>
        <span>{translate(props.title)}</span>
        <Tag style={{ visibility: 'hidden' }} text={translate(props.period)} />
      </WidgetHeader>
      <Table
        columns={cols}
        records={_.map(items, 'columns')}
        order={{ key: 'none', direction: 'asc' } as Ordering<any>}
      />
    </WidgetCard>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 50px;
`

const WidgetCard = styled.div<{ width: string }>`
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 34px 0 rgba(130, 136, 148, 0.21);
  background-color: white;
  font-family: 'Open Sans', sans-serif;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 2%;
  padding-right: 2%;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 50px;
  width: ${(props) => props.width};
`
const WidgetHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 25px;
`

const DigitsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
`
const Digit = styled.div<{ valueColor: string; descriptionColor: string }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;
  min-width: 25%;
  margin-right: 10px;
  > :nth-child(1) {
    color: ${(props) => props.valueColor};
    font-size: 32px;
    font-weight: 600;
  }
  > :nth-child(n + 2) {
    color: ${(props) => props.descriptionColor};
    font-size: 12px;
  }
`

import { Button, Input, Subtitle, NumericInput } from '@duckma/react-ds'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import 'moment/locale/it'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { CustomerCallDetail, CustomerDetail } from '../../data/models'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { ControlledField, ControlledForm } from '../../components/ControlledForm'

moment.locale('it')

const emptyCustomerCallDetails: CustomerCallDetail = {
  user_id: '',
  time_in_call: 0,
  time_off_call: 0,
  description: ''
}

const emptyCustomer: CustomerDetail = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  full_name: '',
  phone_numbers:[],
  service_seconds: 0
}

export const CallWithCustomer = () => {
  const { id_or_phone_number } = useParams<{ id_or_phone_number: string }>()
  const navigate = useNavigate()

  const [getCustomerDetail, { data: customerDataRes }] = useRequest(api.getCustomerDetailsByIDOrPhoneNumber)
  const [saveCustomerCallDetail, { data: customerCallDetailDataRes }] = useRequest(api.saveCustomerCallDetail)

  const [customerDetail, setCustomerDetail] = useState<CustomerDetail>(emptyCustomer)
  const [customerCallDetail, setCustomerCallDetail] = useState<CustomerCallDetail>(emptyCustomerCallDetails)
  const [isTimeInCallTouched, setIsTimeInCallTouched] = useState(false);
  const [isTimeOffCallTouched, setIsTimeOffCallTouched] = useState(false);
  const [isDescriptionTouched, setIsDescriptionTouched] = useState(false);

  useEffect(() => {
    if (customerCallDetailDataRes) {
      navigate('/dashboard')
    }
  }, [customerCallDetailDataRes, navigate])

  const dataObject = useMemo(() => {
    if (customerDataRes) {
      let phone_numbers = [];
      if(!(customerDataRes?.phone_numbers[0]?.number === 'BOGUS_NUMBER')){
         phone_numbers = customerDataRes?.phone_numbers?.length > 0?customerDataRes?.phone_numbers[0]?.country_code+customerDataRes?.phone_numbers[0]?.number:'';
      }
      setCustomerDetail({...customerDataRes,phone_numbers })
      return { ...customerDataRes, full_name:customerDataRes.first_name+" "+customerDataRes.last_name, phone_numbers}
    }
  }, [customerDataRes])

  useCallback((values: any, valid: any) => {
    setCustomerCallDetail((v) => ({ ...v, ...values }))
  }, [])

  // check time_in_call field is touched and less then 0 then show error message
  const timeInCallValid = useMemo(() => {
    if(!isTimeInCallTouched) {
      return true;
    }
    console.log(customerCallDetail?.time_in_call, "customerCallDetail?.time_in_call");
    return customerCallDetail?.time_in_call !== undefined && customerCallDetail?.time_in_call >= 0 
  }, [
    customerCallDetail.time_in_call, isTimeInCallTouched
  ])

  // check time_off_call field is touched and less then 0 then show error message
  const timeOffCallValid = useMemo(() => {
    if(!isTimeOffCallTouched) {
      return true;
    }
    console.log(customerCallDetail?.time_off_call, "customerCallDetail?.time_off_call");
    return customerCallDetail?.time_off_call !== undefined && customerCallDetail?.time_off_call >= 0 
  }, [
    customerCallDetail.time_off_call, isTimeOffCallTouched
  ])

  // check discription field is touched and empty then show error message
  const descriptionValid = useMemo(() => {
    if(!isDescriptionTouched) {
      return true;
    }
    return Boolean(customerCallDetail?.description !== undefined && customerCallDetail?.description?.length > 0)
  }, [
    customerCallDetail.description, isDescriptionTouched
  ])
  const convertSecondsToTime = (seconds:number = 0):string=> {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours} ora ${minutes} minuti ${remainingSeconds} secondo`
  }

  useEffect(() => {
    getCustomerDetail({ id_or_phone_number })
  }, [getCustomerDetail, id_or_phone_number])

  return (
    <Container>      
      <InstructionsBox>
      <InstructionBoxItem>     
        <SubtitleUpdate>Istruzioni</SubtitleUpdate>
        <Text>
          Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus,
          scelerisque nec pharetra id, tempor a tortor. Pellentesque non dignissim neque. Ut porta
          viverra est, ut dignissim elit elementum ut. Nunc vel rhoncus nibh, ut tincidunt turpis.
          Integer ac enim pellentesque, adipiscing metus id, pharetra odio. Donec bibendum nunc
          sit amet.
        </Text>
        </InstructionBoxItem>
        <SecondInstructionBoxItem>
        <SubtitleUpdate>Tempo rimanente del servizio MySecretary</SubtitleUpdate>
        <SectionSubtitleService>
         {convertSecondsToTime(customerDataRes?.service_seconds)}      
        </SectionSubtitleService>
        {customerDataRes?.service_seconds && customerDataRes.service_seconds < 300 && (
          <p style={{ backgroundColor: "red", color: "white" }}>
            <b>Ricordare al Cliente che, per usufruire del servizio, dovrà provvedere ad effettuare una ricarica</b>
          </p>
        )}
        </SecondInstructionBoxItem>
      </InstructionsBox>      
      <Separator />
      <FormContainer>
        <ControlledForm initialValues={customerDataRes ? (dataObject as any) : null}>
          <ControlledField disabled name="Utente in entrata" fieldName="full_name" />
          <ControlledField disabled name="Cliente" fieldName="email" />
          <ControlledField disabled name="Numero di telefono cliente" fieldName="phone_numbers" />
        </ControlledForm>
      </FormContainer>

      <Separator />

      <FormContainer>
        <SectionSubtitle>Lascia messaggio</SectionSubtitle>
          <InputContainer>
            <NumericInput
              name="Tempo speso in chiamata (in minuti)-*" 
              value={customerCallDetail.time_in_call}
              onChange={(time_in_call) => setCustomerCallDetail((data) => ({ ...data, time_in_call }))}
              valid={timeInCallValid}
              onBlur={() => setIsTimeInCallTouched(true)}
              errorText="Inserici un valore numerico intero superiore a 0"
            />
          </InputContainer>

          <InputContainer>
            <NumericInput
              name="Tempo speso in assistenza (in minuti)-*"
              value={customerCallDetail.time_off_call}
              onChange={(time_off_call) => setCustomerCallDetail((data) => ({ ...data, time_off_call }))}
              valid={timeOffCallValid}
              onBlur={() => setIsTimeOffCallTouched(true)}
              errorText="Inserici un valore numerico intero superiore a 0"
            />
          </InputContainer>

          <Input
            style={{ flexBasis: '100%', marginBottom: '5px' }}
            placeholder="Inserisci messaggio"
            name="Messaggio per l’utente-*"
            value={customerCallDetail.description}
            onChange={(description) => setCustomerCallDetail((data) => ({ ...data, description }))}
            rows={5}
            valid={descriptionValid}
            onBlur={() => setIsDescriptionTouched(true)}
            errorText="Il campo è obbligatorio"
          />

          <div style={{ flexBasis: '100%', marginBottom: '5px' }}>
            <DescriptionText>Policy text</DescriptionText>
            <DescriptionText>
              Considerare la chiamata come "urgente" se si tratta di: incidenti, lutto, ecc.
            </DescriptionText>
          </div>
      </FormContainer>

      <ConfirmBox>
        <ConfirmButton
          text="Chiudi"          
          disabled={
            !isDescriptionTouched || !timeInCallValid || !timeOffCallValid || !descriptionValid
          }
          onClick={async (e) => {
            e.preventDefault()
            setCustomerCallDetail((data) => ({ ...data }))
            saveCustomerCallDetail({...customerCallDetail, user_id: customerDetail.id})
          }}
        />
        <DescriptionText style={{ marginTop: '15px' }}>
          Premere chiudi per confermare
        </DescriptionText>
      </ConfirmBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 50px;
`

const Separator = styled.div`
  background-color: #fff;
  height: 10px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
`
const InstructionsBox = styled.div`
  width: 100%;
  padding: 0 50px;
  display:flex;
`
const InstructionBoxItem = styled.div`
  width:100%
  flex-grow: 1;
  margin-right: 10%
`
const SecondInstructionBoxItem = styled.div`
  width:100%
`;

const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: space-between; 
  width:100%;
`
const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px;
  justify-content: space-between;
  > * {
    flex-basis: 32%;
    margin-bottom: 20px;
  }
`

const SectionSubtitle = styled(Subtitle)`
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-bottom: 30px  !important;
  flex-basis: 100% !important;
`
const SectionSubtitleService = styled(Subtitle)`
  font-size: 20px !important;
  font-weight: 550 !important;
  margin-top: 10px  !important;
  flex-basis: 100% !important;
  color: #3b7b95  !important;
`
const SubtitleUpdate = styled(Subtitle)`
  color: black !important;
`;

const DescriptionText = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #828894;
  margin: 2px;
`
const ConfirmBox = styled.div`
  width: 100%;
  margin: 20px 50px;
  > * {
    float: right;
  }
`
const ConfirmButton = styled(Button)`
  width: fit-content !important;
  margin-left: 20px !important;
  padding: 12px 20px !important;
`
const InputContainer = styled.div`
  width: 100%;
  justify-content: space-between;
`